<template>
  <div class="c-app ">
    <CContainer fluid class="bg-white">
      <CRow  class="align-items-center">
        <CCol lg="7" md="12" sm="12" xs="12" ><div class="responsive-sizer d-md-down-none "><img src="@/assets/images/auth/img-auth-sideimg.png" class="cimap"></div></CCol>
        <CCol  lg="5" md="12" sm="12" xs="12"class=" justify-content-center align-items-center">

            <CCard class="border-0" style="max-width:100%;width:400px;margin:0 auto">
              <CCardBody class="p-4 border-0"  >
                <CForm >
                  <CSidebarBrand class="color-dark" >
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="65"
        viewBox="-50 0 456 104"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="25"
        viewBox="0 0 175 134"
      />
    </CSidebarBrand>
                  <p class="text-muted text-center">Prihlásenie</p>
                  <CInput
                    placeholder="Unit ID"
                    autocomplete="UnitID"
                     v-model="model.unitID"
                  >
                    <template #prepend-content><CIcon name="cil-user" /></template>
                  </CInput>
                       <CInput
                    placeholder="Username"
                    autocomplete="username email"
                      v-model="model.username"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                     v-model="model.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-center">
                      <CButton color="primary" class="px-4 mt-3 w-100" @click="handleSubmit">Prihlásiť</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>



    export default {
        name: 'login',
        data() {
            return {
                model: {
                    username: '',
                    password: '',
                    unitID: '',
                    submitted: false
                }
            }
        },
        computed: {
            loggingIn () {
                return this.$store.state.authentication.status.loggingIn;
            },
            alert () {
                return this.$store.state.alert
            }
        },
        created () {

        },
        methods: {
            handleSubmit () {
                this.submitted = true;
                const { username, password, unitID } = this.model;


                const { dispatch } = this.$store;
                if (username && password) {
                    dispatch('authentication/login', { username, password, unitID });
                }
            }
        }
    };
</script>
<style>
.cimap
{
  object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100vh;
    width:100%;
}

.responsive-sizer
{    padding-bottom: 86.5278%;
    flex: 1 0 0px;
    transition: padding-bottom 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
}
</style>
